import React, { useEffect, useRef, useState } from "react";
import "./feedBack.scss";
import axios from "axios";

const FeedbackPage = () => {
  const formRef = useRef();
  const challenges = [
    "What to do next?",
    "What to do in the Gamers Lounge?",
    "How to customize the personal room?",
    "How to invite friends?",
    "What to do in the shop?",
  ];
  const [messageData, setMessageData] = useState({
    challenge: "",
    message: "",
    email: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCompulsory, setIsCompulsory] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false)
  useEffect(() => {
    document.body.classList.add("scrollbar-vgn");

    return () => {
      document.body.classList.remove("scrollbar-vgn");
    };
  }, []);

  useEffect(() => {
    setIsDisabled(false);
    setIsCompulsory(false);
    if (messageData?.email?.length === 0) {
      setIsDisabled(true);
      setIsCompulsory(true);
    }
  }, [messageData?.email?.length]);

  const handleCheckboxChange = (e) => {
    const index = parseInt(e.target.id, 10);
    const selectedChallenge = challenges[index];

    if (e.target.checked) {
      setMessageData((prevData) => ({
        ...prevData,
        challenge: [...prevData.challenge, selectedChallenge],
      }));
    } else {
      setMessageData((prevData) => ({
        ...prevData,
        challenge: prevData.challenge.filter((ch) => ch !== selectedChallenge),
      }));
    }
  };

  const handleMessageChange = (e) => {
    setMessageData((prevData) => ({
      ...prevData,
      message: e.target.value,
    }));
  };
  const handleEmailChange = (e) => {
    setEmailError("");
    setMessageData((prevData) => ({
      ...prevData,
      email: e.target.value,
    }));
  };
  const generateHTML = (data) => {
    let htmlString = `
    <div>
    ${
      data.email &&
      `<h3>Sender: <span style="font-weight: normal;">${data.email.toString()}</span></h3>`
    }
      ${
        data.challenge &&
        `<h3>Challenges: <span style="font-weight: normal;">${data.challenge.toString()}</span></h3>`
      }
      ${
        data.message &&
        `<h3>Message: <span style="font-weight: normal;">${data.message}</span></h3>`
      }
    </div>
  `;

    return htmlString;
  };
  const handleWebViewClose = () => {
    if (window.Unity != null) {
      const message = "email sent";
      console.log("Sending from JSLIb");
      window.Unity.call(message);
    } else {
      console.log("Unable to find Unity object");
      window.close();
    }
  };

  const validateEmail = (email) => {
    if (!email) {
      setEmailError("Invalid email address !");
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Invalid email address !");
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateEmail(messageData.email)) {
      const html = generateHTML(messageData);
      setIsDisabled(true);
      if (window?.grecaptcha)
        window?.grecaptcha.ready(function () {
          window?.grecaptcha
            .execute(process.env.REACT_APP_V3_CAPTCHA_SITE_KEY, {
              action: "submit",
            })
            .then(async function (token) {
              const payload = {
                subject: "Gamers Lounge Inapp Feedback",
                message: html,
              };
              await axios
                .post(`${process.env.REACT_APP_GAMING_API}/email`, payload, {
                  headers: {
                    Authorization: `${token}`,
                    "Content-Type": "application/json",
                  },
                })
                .then(() => {
                  setMessageData({
                    challenge: "",
                    message: "",
                    email: "",
                  });
                  formRef.current.reset();
                  setIsEmailSent(true);
                  setTimeout(() => {
                    setIsEmailSent(false)
                    handleWebViewClose();
                  }, 3000);
                })
                .catch((error) => {
                  setIsDisabled(false);
                });
            });
        });
    }
  };
  return (
    <div className="feedback-page">
      <h2>Feedback!</h2>
      <p>
        What do you find the biggest challenges in the Gamers Lounge? (Tick all
        that apply)
      </p>
      <form ref={formRef}>
        <ul>
          {challenges.map((challenge, index) => (
            <li key={challenge} className="custom_checkbox">
              <input
                type="checkbox"
                id={index}
                onChange={(e) => handleCheckboxChange(e)}
              />
              <label htmlFor={index}>{challenge}</label>
            </li>
          ))}
        </ul>
        <div className="input-top-holder">
          <input
            type="text"
            className={`form-control input-holder ${
              (emailError || isCompulsory) && `warning`
            }`}
            placeholder="Enter your email here"
            onChange={(e) => handleEmailChange(e)}
            value={`${messageData.email}`}
          />
          <span className="error-msg">{emailError && <>{emailError}</>}</span>
        </div>
        <textarea
          placeholder="Tell us about your experience!"
          className="textarea-holder"
          onChange={(e) => handleMessageChange(e)}
        />
        <div className="text-center">
          <button
            className={`custom_btn ${isDisabled && "disabled"}`}
            onClick={(e) => handleSubmit(e)}
            disabled={isDisabled}
          >
            Send Feedback
          </button>
        </div>
      </form>
      {/* <p className="copy_right">
        <a
          href={process.env.REACT_APP_DISCORD_JOIN_URL}
          target="_blank"
          rel="no-referrer"
        >
          Join our Discord
        </a>{" "}
        to stay up to date and connect with the rest of our community
          </p>*/}
      <button className="modals-close-cross" onClick={handleWebViewClose}>
        <img
          src="https://storage.googleapis.com/cdn.bimtvist.com/dashboard/images/close.svg"
          alt=""
        />
      </button>
      <section className={`feedback-success ${isEmailSent && "active"}`}>
        Your feedback has been sent successfully.
      </section>
    </div>
  );
};

export default FeedbackPage;

import { useAppContext } from 'AppContextProvider'
import { getGamerLoungeDocData } from 'firestore/firestoreData'
import useCustomKeycloak from 'keyclock/useKeyclock'
import React, { useEffect, useReducer, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { DASHBOARD_CLAIM_LINK } from 'utils/constants'
import { boxGamerIcon } from '../../assets/images/images'
import { sendGtagConverionEvent } from 'utils/googleTagConversion'

function ClaimGamerLounge({ showClaimGamerModal, hideClaimModal }) {
  const { doLogin, userInfo } = useCustomKeycloak()
  const { appState } = useAppContext()
  const refGamerLounge = useRef(null)
  const handleClaim = () => {
    if (userInfo == null) {
      doLogin(`${window.location.origin}?handle_claim=true`)
      return
    }

    if (appState?.is_gamers_lounge_claimed === false) {
      window.open(DASHBOARD_CLAIM_LINK, '_blank')
      hideClaimModal()
    } else {
      window.open(refGamerLounge.current?.external_link, '_blank')
      hideClaimModal()
    }

    sendGtagConverionEvent('tog_vgn_button_clicked', 'AW-11303637897/szHeCIHZt9sYEImn_40q', '')
  }
  useEffect(() => {
    if (showClaimGamerModal && appState?.is_gamers_lounge_claimed === true) {
      getGamerLoungeDocData()
        .then((gamerLounge) => {
          refGamerLounge.current = gamerLounge
        })
        .catch((ex) => {})
      sendGtagConverionEvent('tog_vgn_pop_up', 'AW-11303637897/QijcCISbttsYEImn_40q', '')
    }
  }, [showClaimGamerModal, appState?.is_gamers_lounge_claimed])
  return (
    <>
      {showClaimGamerModal && (
        <Modal
          size="w-650"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
          show
          className="claimModal">
          <div className="game-modal game-modal-game">
            <img
              src={process.env.REACT_APP_S3_IMGS_URL + 'close.svg'}
              alt="close"
              className="modal-close-icon"
              onClick={hideClaimModal}
            />
            <div className="modal-holder">
              <div className="modal-content-game relative">
                <div className="inner-content">
                   <h3 className="modal-title">Try other games from the Virtua Games Network!</h3>
                  <img src={boxGamerIcon} alt="box" />
                  {/*<p>Try these other games by the Virtua Games Network!</p>*/}
                  <button className="claim-btn" onClick={handleClaim}>
                    Game On
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ClaimGamerLounge

import AppLoader from 'components/apploader'
import useCustomKeycloak from 'keyclock/useKeyclock'
import { createContext, useContext, useEffect, useState } from 'react'
import { DASHBOARD_CLAIM_LINK } from 'utils/constants'
interface ContaxtState {
  balanceData: null | any
  is_gamers_lounge_claimed: boolean
}
export const AppContext = createContext<any>({ appState: null, setAppState: null })
function AppContextProvider({ children }: any): JSX.Element {
  const { isKeycloakInit } = useCustomKeycloak()

  const [appState, setAppState] = useState<ContaxtState>({
    balanceData: null,
    is_gamers_lounge_claimed: false
  })
  // useEffect(() => {
  //   if (window.location?.pathname == '/handle_claim') {
  //     // handle_claim path does not exist. Route will rediract to home after dashboard page opening
  //     if (appState?.is_gamers_lounge_claimed === false) {
  //       // window.open(DASHBOARD_CLAIM_LINK, '_blank')
  //     }
  //   }
  // }, [])

  if (!isKeycloakInit) {
    return <AppLoader />
  }

  const updateAppState = (newData: any): void => {
    setAppState((prevData) => ({ ...prevData, ...newData }))
  }
  return <AppContext.Provider value={{ appState, updateAppState }}>{children}</AppContext.Provider>
}

export default AppContextProvider

export const useAppContext = () => useContext(AppContext)

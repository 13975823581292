import axios from 'axios'
import keycloak from 'keyclock/keyclock'
import { GAMING_API } from 'utils/constants'

const gamingAxios = axios.create({
  baseURL: GAMING_API,
  timeout: 60000
})
gamingAxios.interceptors.request.use(function (config) {
  config.headers.Authorization = keycloak?.token ?? ''
  return config
})
export default gamingAxios

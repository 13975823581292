import { useAppContext } from 'AppContextProvider'
import { getBanners } from 'firestore/firestoreData'
import useCustomKeycloak from 'keyclock/useKeyclock'
import { memo, useEffect, useState } from 'react'
import { DASHBOARD_CLAIM_LINK } from 'utils/constants'

const GameDetailComponent = () => {
  const [bannersData, setBannersData] = useState([])
  const { userInfo, doLogin } = useCustomKeycloak()
  const { appState } = useAppContext()
  useEffect(() => {
    getBanners()
      .then((bannersData) => {
        setBannersData(bannersData ?? [])
      })
      .catch((ex) => {})
  }, [])

  const getBtnTitle = (banner) => {
    if (banner.type === 'gamers_lounge') {
      if (userInfo == null) {
        return banner?.cta_title_lock
      }
      if (appState?.is_gamers_lounge_claimed === false) {
        return banner?.cta_title_lock
      }
    }
    return banner?.cta_title_active
  }
  const handleBtnClick = (banner) => () => {
    if (banner.type === 'gamers_lounge') {
      if (userInfo == null) {
        doLogin()
        return
      }
      if (appState?.is_gamers_lounge_claimed === false) {
        window.open(DASHBOARD_CLAIM_LINK, '_blank')
        return
      }
    }

    window.open(banner?.external_link, '_blank')
  }
  const getCustomStyle = (banner) => {
    if (banner.type === 'jetpack') {
      return 'jetpack-banner-style'
    }
    return ''
  }
  return (
    <div className="gaming-right-side">
      {bannersData?.map((banner) => (
          <div className="col-width" key={banner.docID}>
            <div className={`relative ${getCustomStyle(banner)}`} key={banner.docID}>
              <img src={banner?.image} className="banner-img" alt="gaming" />
              <div className="text">
                <h3 className="max-100">{banner?.heading ?? ''}</h3>
                <p>{banner?.description ?? ''}</p>
                <button className="custom-btn purple md fw-600" onClick={handleBtnClick(banner)}>
                  {getBtnTitle(banner)}
                </button>
              </div>
            </div>
          </div>
      ))}
    </div>
  )
}

export default memo(GameDetailComponent)

import { memo } from 'react'

const GameDetailComponent = ({ seletedGame }) => {
  const { attributes } = seletedGame ?? {}
  return (
    <div className={`gaming-description ${seletedGame ? '' : 'd-none'}`}>
      <div className="head-area">
        <h3>{seletedGame?.name ?? ''}</h3>
        <ul className="list-none flex column-direction">
          {/*<li>*/}
          {/*  <span>Developer:</span> {attributes?.developer ?? 'Virtua'}*/}
          {/*</li>*/}
          <li key="ReleasedKey">
            <span>Released:</span> {attributes?.release ?? ''}
          </li>
          <li key="TecKey">
            <span>Technology:</span> {attributes?.technology ?? ''}
          </li>
          <li>
            <span key="PlatformKey">Platforms:</span>
            {attributes?.platforms ?? ''}
          </li>
          <li key="ControlKey">
            <span>Controls:</span> {attributes?.controls ?? ''}
          </li>
        </ul>
      </div>
      <div className="bottom-area mt-5">
        <p>{seletedGame?.description ?? ''}</p>
        {/*<h3 className="title">Features</h3>*/}
        {/*<ul className="feature flex column-direction">{attributes?.features?.join(', ') ?? ''}</ul>*/}
        
        {/*<ul className="feature list-none pl-0 flex feature-holder">*/}
        {/*  <li className="flex column-direction flex-text-center gap-20 fs-24 fw-600">*/}
        {/*    Features*/}
        {/*    <span className="purple">{attributes?.release ?? ''}</span>*/}
        {/*  </li>*/}
        {/*  <li className="flex column-direction flex-text-center gap-20 fs-24 fw-600">*/}
        {/*    Platform*/}
        {/*    <span>{attributes?.platforms ?? ''}</span>*/}
        {/*  </li>*/}
        {/*  <li className="flex column-direction flex-text-center gap-20 fs-24 fw-600">*/}
        {/*    Controls*/}
        {/*    <span>{attributes?.controls ?? ''}</span>*/}
        {/*  </li>*/}
        {/*</ul>*/}
      </div>
    </div>
  )
}

export default memo(GameDetailComponent)

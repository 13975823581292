export const iosLink = 'https://apps.apple.com/us/app/jetpack-hyperleague/id6451138161'
export const androidLink =
  'https://play.google.com/store/apps/details?id=com.virtua.jetpack.hyperleague'

export const LEDGER_API_BASE = process.env.REACT_APP_LEADER_API
export const GAMING_API = process.env.REACT_APP_GAMING_API
export const DASHBOARD_CLAIM_LINK = process.env.REACT_APP_DASHBOARD_CLAIM_LINK

export const SOCIAL_ICONS = [
  // {
  //     src: "https://assets-cdn.virtua.com/images/play/jetpack/facebook.svg",
  //     alt: "facebook",
  //     href: "https://www.facebook.com/VirtuaMetaverse/",
  // },
  {
      src: "https://assets-cdn.virtua.com/images/play/jetpack/twitter.svg",
      alt: "twitter",
      href: "https://twitter.com/virtua_official",
  },
  {
      src: "https://assets-cdn.virtua.com/images/play/jetpack/instagram.svg",
      alt: "instagram",
      href: "https://www.instagram.com/Virtuametaverse/",
  },
  // {
  //     src: rabbit,
  //     alt: "rabbit",
  //     href: "",
  // },
  {
      src: "https://assets-cdn.virtua.com/images/play/jetpack/youtube.svg",
      alt: "youtube",
      href: "https://www.youtube.com/VirtuaMetaverse",
  },
  {
      src: "https://assets-cdn.virtua.com/images/play/jetpack/medium.svg",
      alt: "medium",
      href: "https://medium.com/virtuametaverse",
  },
  // {
  //     src: telegram,
  //     alt: "telegram",
  //     href: "",
  // },
];

export const SOCIAL_ICONS_GAMES = [
    {
        src: "https://assets-cdn.virtua.com/images/Virtua_home/facebook.svg",
        href: "https://www.facebook.com/VirtuaMetaverse/",
        alt: "facebook",
    },
    {
        src: "https://assets-cdn.virtua.com/images/Virtua_home/twitter.svg",
        href: "https://twitter.com/Virtuametaverse",
        alt: "twitter",
    },
    {
        src: "https://assets-cdn.virtua.com/images/Virtua_home/instagram.svg",
        href: "https://www.instagram.com/Virtuametaverse/",
        alt: "instagram",
    },
    {
        src: "https://assets-cdn.virtua.com/images/Virtua_home/youtube.svg",
        href: "https://www.youtube.com/VirtuaMetaverse",
        alt: "youtube",
    },
    {
        src: "https://assets-cdn.virtua.com/images/Virtua_home/discord.svg",
        href: "https://discord.com/invite/virtua",
        alt: "discord",
    },
    // {
    //   src: reddit,
    //   href: "https://www.reddit.com/user/terravirtua",
    //   alt: "reddit",
    // },
    {
        src: "https://assets-cdn.virtua.com/images/Virtua_home/medium.svg",
        href: "https://medium.com/terravirtua",
        alt: "medium",
    },
    // {
    //   src: telegram,
    //   href: "https://t.me/Virtuametaverse",
    //   alt: "telegram",
    // },
];

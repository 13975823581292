import { useAppContext } from 'AppContextProvider'
import { getGameConversionRate } from 'data/ledger/ledgerApiCalls'
import useCustomKeycloak from 'keyclock/useKeyclock'
import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { DASHBOARD_CLAIM_LINK } from 'utils/constants'
import {boxIcon, jetpackReward, jetReward, roomReward} from '../../assets/images/images'
import { sendGtagConverionEvent } from 'utils/googleTagConversion'

function LoginHighXPModal({ showClaimXPModal, hideClaimModal, game, score }) {
  const { doLogin, userInfo } = useCustomKeycloak()
  const [xpValue, setXPValue] = useState(0)
  const { appState } = useAppContext()
  const handleClaim = () => {
    if (userInfo == null) {
      // doLogin(`${window.location.origin}/handle_claim`)
      doLogin(`${window.location.origin}?handle_claim=true`)
      return
    }

    window.open(DASHBOARD_CLAIM_LINK, '_blank')
    sendGtagConverionEvent('high_score_button_clicked', 'AW-11303637897/-7rrCJHIt9sYEImn_40q', '')
  }

  useEffect(() => {
    if (showClaimXPModal && game?.uuid) {
      getGameConversionRate(game?.uuid)
        .then((res) => {
          if (res?.rate) {
            setXPValue((res?.rate ?? 0) * score)
          }
        })
        .catch((ex) => {})

      sendGtagConverionEvent('high_score_pop_up', 'AW-11303637897/YuxYCMeQttsYEImn_40q', '')
    }
  }, [game?.uuid, showClaimXPModal])

  const getXP = useCallback(() => {
    if (xpValue == 0) return ''
    return Number.isInteger(xpValue) ? xpValue : xpValue?.toFixed(2)
  }, [xpValue])
  return (
    <>
      {showClaimXPModal && (
        <Modal
          size="w-650"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
          show
          className="claimModal">
          <div className="game-modal">
            <img
              src={process.env.REACT_APP_S3_IMGS_URL + 'close.svg'}
              alt="close"
              className="modal-close-icon"
              onClick={hideClaimModal}
            />
            <div className="modal-holder">
              <div className="modal-content-game relative">
                <div className="inner-content">
                  <h3 className="modal-title">High Score!</h3>
                  <div className="d-flex align-items-center justify-content-center high-boxes">
                    <section className="high-score-box one"><img src={jetpackReward} alt="box" /></section>
                    <section className="high-score-box"><img src={jetReward} alt="box" /></section>
                    <section className="high-score-box"><img src={roomReward} alt="box" /></section>
                  </div>
                  <p className="max-100">
                    Use the {getXP()} XP you've earned from your high score to unlock exclusive
                    Season Rewards in the Prime Dashboard.
                  </p>
                  <button className="claim-btn" onClick={handleClaim}>
                    Let's Go
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default LoginHighXPModal

/* eslint-disable */
export const sendGtagConverionEvent = (conversionName, sentTo, url) => {
    (async () => {
        const callback = () => {
            if (typeof url !== 'undefined') {
                window.location = url
            }
        }
        window.gtag('event', conversionName, {
            send_to: sentTo
            // 'event_callback': callback
        })
    })()
}

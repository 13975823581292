import { AppContext, useAppContext } from 'AppContextProvider'
import { updateUserData } from 'data/ledger/ledgerApiCalls'
import useCustomKeycloak from 'keyclock/useKeyclock'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { setDeviceIDFromGames } from 'utils/utils'
import ClaimGamerLounge from '../modals/ClaimGamerLounge'
import ClaimXPModal from '../modals/ClaimXPModal'
import LoginXPModal from '../modals/LoginHighXPModal'
function GameIframeComponent({ gameItem }) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isGameStarted, setIsGameStarted] = useState(false)
  const { updateAppState, appState } = useAppContext(AppContext)
  const [isShowClaimModal, setIsShowClaimModal] = useState(false)
  const [isShowClaimGamerModal, setIsShowClaimGamerModal] = useState(false)
  const [isShowHighScoreModal, setIsShowHighScoreModal] = useState(false)
  const [scoreValue, setScoreValue] = useState(0)
  const [isLoadingFrame, setUsLoadingFrame] = useState(false)
  const [isLoadingIcon, setIsLoadingIcon] = useState(false)
  const { userInfo } = useCustomKeycloak()
  const [searchParams, setSearchParams] = useSearchParams()
  const { attributes } = gameItem ?? {}
  const webViewRef = useRef(null)
  const refScoreValueMap = useRef(new Map())
  const refTurnCount = useRef(1)
  const handleClick = () => {
    setIsPlaying((prevValue) => !prevValue)
  }
  const handleMouseEnter = useCallback(() => {
    if (isPlaying) {
      document.body.classList.add('overflow-container')
    }
  }, [isPlaying])

  const handleMouseLeave = useCallback(() => {
    document.body.classList.remove('overflow-container')
  }, [])
  useEffect(() => {
    setIsPlaying(false)
    if (attributes?.game_link) {
      setIsGameStarted(true)
      setUsLoadingFrame(true)
      setScoreValue(0)
      // refTurnCount.current = 1
      // refScoreValue.current = 0
      setTimeout(() => {
        setUsLoadingFrame(false)
      }, 30000)
    }

    if (attributes?.icon) {
      setIsLoadingIcon(true)
    }
  }, [gameItem])

  useEffect(() => {
    const handleIframeMessage = (event) => {
      const messageData = event.data

      if (messageData?.type == 'XP_EARNED' && messageData.score > 0) {
        if (userInfo !== null) {
          updateUserData(updateAppState, userInfo)
        }
        if (userInfo !== null) {
          let gameScore = refScoreValueMap.current.get(gameItem?.name?.replace(' ', '_')) ?? 0
          if (gameScore !== 0 && messageData.score > refScoreValueMap.current.get(gameItem?.name?.replace(' ', '_'))) {
            setIsShowHighScoreModal(true)
            refScoreValueMap.current.set(gameItem?.name?.replace(' ', '_'), messageData.score)
          } else {
            if (refTurnCount.current % 2 !== 0) setIsShowClaimGamerModal(true)

            refTurnCount.current = refTurnCount.current + 1
          }
          if (gameScore == 0) {
            refScoreValueMap.current.set(gameItem?.name?.replace(' ', '_'), messageData.score)
          }
        } else {
          setDeviceIDFromGames(messageData.uuid ?? '')
          setScoreValue(messageData.score)
          setIsShowClaimModal(true)
        }
      }
    }

    window.addEventListener('message', handleIframeMessage)
    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [userInfo, gameItem?.name])
  useEffect(() => {
    if (searchParams.get('handle_claim')) {
      setIsShowClaimGamerModal(true)
      searchParams.delete('handle_claim')
      setSearchParams(searchParams)
      refTurnCount.current = 2
    }
  }, [searchParams])

  const onIconLoaded = () => {
    setIsLoadingIcon(false)
  }

  return (
    <div
      className={`game-play-container ${!isPlaying && 'gaming-play'}`}
      key={'frame_' + gameItem?.name}>
      {isGameStarted && attributes?.game_link && (
        <iframe
          ref={webViewRef}
          src={attributes.game_link}
          width={'100%'}
          className="iframe-height"
          onLoad={() => {
            setTimeout(() => {
              setUsLoadingFrame(false)
            }, 4000)
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          allowFullScreen={true}
          scrolling="no"
          noresize="noresize"
        />
      )}
      {/* <iframe src={'http://127.0.0.1:5500/rainbow-arrow/index.html'} width={'100%'} height={500} /> */}
      {/* {!isPlaying && gameItem?.thumbnail && (
        <img src={gameItem?.thumbnail ?? ''} className="overlay-game-img" />
      )} */}

      {!isPlaying && gameItem && (
        <div className="play-btn-container">
          <div className="mob-mode">
            <img
              src={attributes?.icon ?? ''}
              onLoad={onIconLoaded}
              onError={onIconLoaded}
              onAbort={onIconLoaded}
            />
            {isLoadingIcon && <div className="loader-icon shimmer" />}
          </div>

          <h2>{gameItem?.name ?? ''}</h2>
          <button className="custom-btn purple fw-600 lg rounded play-now" onClick={handleClick}>
            Play Now
            <svg
              width="22"
              height="22"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 0C6.27291 0 0 6.27291 0 14C0 21.7271 6.27291 28 14 28C21.7271 28 28 21.7271 28 14C28 6.27291 21.7271 0 14 0ZM14 25.6334C7.58452 25.6334 2.3666 20.4155 2.3666 14C2.3666 7.58452 7.58452 2.3666 14 2.3666C20.4155 2.3666 25.6334 7.58452 25.6334 14C25.6334 20.4155 20.4155 25.6334 14 25.6334Z"
                fill="white"
              />
              <path
                d="M11.5764 18.8187C11.7475 18.9043 11.9186 18.9328 12.0897 18.9328C12.2893 18.9328 12.5174 18.8758 12.6885 18.7332L18.4481 14.7984C18.7333 14.5988 18.9043 14.2566 18.9043 13.9145C18.9043 13.5723 18.7333 13.2301 18.4196 13.0305L12.6599 9.20977C12.3178 8.98166 11.9186 8.98166 11.5479 9.15274C11.2058 9.35233 10.9777 9.69449 10.9777 10.0937V17.8493C11.0062 18.277 11.2058 18.6476 11.5764 18.8187Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      )}

      {gameItem == null && (
        <>
          <div className="main-video-holder">
            <video className="overlay-default-img" autoPlay loop muted playsInline>
              <source
                src={`${process.env.REACT_APP_S3_ASSETS_URL}project_02.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
          {/*<img src={bgImage} alt="gaming" className="overlay-default-img" />*/}
          <div className="play-btn-container w-100">
            <svg
              className="mb-4 svg-logo"
              xmlns="http://www.w3.org/2000/svg"
              width="157"
              height="161"
              viewBox="0 0 157 161"
              fill="none">
              <path
                d="M126.156 87.4445H97.1758C95.2801 87.4445 93.7433 88.9734 93.7433 90.8592V122.99C98.226 122.125 102.429 120.567 106.265 118.351C112.528 114.734 117.554 109.5 121.201 102.798C123.243 99.0462 124.735 94.8892 125.639 90.4437C125.838 89.4736 126.01 88.4691 126.156 87.4445Z"
                fill="white"
              />

              <path
                d="M85.1911 138.051C85.0796 138.053 84.9661 138.053 84.8526 138.053C78.2653 138.053 72.0694 137.159 66.3114 135.388L76.692 154.291C78.5309 157.341 81.5965 161 85.2743 161C89.5644 161 91.4053 157.341 93.2442 154.291L104.3 134.936C98.3615 136.955 92.0217 138 85.3493 138.049L85.1911 138.051Z"
                fill="white"
              />

              <path
                d="M56.6935 27.3322C63.4307 23.514 70.9324 21.2086 78.9935 20.4765L79.0746 20.4684H79.1557L80.1046 20.4583C78.4259 16.9931 76.7066 13.4412 74.8555 9.75819C71.1756 3.04969 66.2712 0 59.5279 0C42.9756 0 26.4255 0 9.87325 0C3.1299 0 -0.54792 4.26795 0.0664011 10.9764C0.678695 15.2444 2.51761 18.9052 4.35652 22.564L29.0693 67.5671C30.2756 60.0639 32.816 53.0932 36.654 46.7719C41.6193 38.591 48.3626 32.0519 56.6935 27.3322Z"
                fill="white"
              />

              <path
                d="M98.761 53.6659C94.1079 47.1308 90.4342 40.8399 87.0828 34.4662C86.6611 34.4561 86.2393 34.45 85.8156 34.45C77.8842 34.45 70.6806 36.2895 64.4036 39.9161C58.1407 43.5325 53.1146 48.7666 49.4672 55.4691C47.4256 59.2207 45.9334 63.3777 45.0291 67.8231C44.3094 71.3609 43.9444 75.167 43.9444 79.1344C43.9444 80.2094 43.9728 81.3026 44.0276 82.3797C44.3864 89.3827 45.9557 95.7705 48.6927 101.364C48.9725 101.938 49.228 102.435 49.4713 102.884C49.7592 103.417 50.0775 103.974 50.4729 104.635L50.4911 104.667L50.5094 104.7C53.4755 110.105 57.6116 114.526 62.7998 117.842C66.9703 120.507 71.7024 122.298 76.8826 123.175V84.9332C76.8826 77.067 83.3158 70.6671 91.2229 70.6671H141.017L157 42.6874C147.189 53.6659 123.281 81.109 98.761 53.6659Z"
                fill="white"
              />
            </svg>
            <h1>Virtua Games Network</h1>
            <p className="text-center">
              Claim your XP to win exclusive rewards including vCoin packs, resources and also get
              access to a free Gamers Lounge, where you can experience more VGN games!
            </p>
          </div>
        </>
      )}
      {isLoadingFrame && <div className="loader-game-frame shimmer" />}
      <ClaimXPModal
        showClaimXPModal={isShowClaimModal}
        hideClaimModal={() => setIsShowClaimModal(false)}
        score={scoreValue}
        game={gameItem}
      />
      <ClaimGamerLounge
        showClaimGamerModal={isShowClaimGamerModal}
        hideClaimModal={() => setIsShowClaimGamerModal(false)}
      />
      <LoginXPModal
        showClaimXPModal={isShowHighScoreModal}
        hideClaimModal={() => setIsShowHighScoreModal(false)}
        score={scoreValue}
        game={gameItem}
      />
    </div>
  )
}

export default GameIframeComponent

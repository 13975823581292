import React from 'react'
import './style.scss'

const AppLoader = () => {
  return (
    <div className="loader-config-container">
      <div className='loader'><img src={process.env.REACT_APP_S3_IMGS_URL + "tv_loader.gif"} alt="" /></div>
    </div>
  )
}
export default AppLoader


import FeedbackPage from 'components/feedback-page/FeedbackPage';
import HTMLGamesComponent from 'htmlgames/HTMLGamesComponent.jsx';
import React, { Suspense, lazy } from 'react'
import { Routes as Switch, Route, Navigate } from 'react-router-dom';

const Home = lazy(() => import("views/home"));
const ComingSoon = lazy(() => import("views/coming-soon"));
const PrivacyPolicy = lazy(() => import("views/privacy-policy"));


const RoutesComponent = () => {
    return (
        <Switch>
            <Route path='/' element={<HTMLGamesComponent/>} />
            <Route path='/:gameName' element={<HTMLGamesComponent/>} />
            <Route path='/index.html' element={<HTMLGamesComponent/>} />
            <Route path='/jetpack' element={<Suspense><Home /></Suspense>} />
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
            <Route path='/feedback' element={<FeedbackPage/>} />
            <Route path='*' element={<Navigate to={'/'} />} />
        </Switch>
    )
}

export default RoutesComponent

import { useAppContext } from 'AppContextProvider.tsx'
import { virtuaUser } from 'assets/index.js'
import { updateUserData } from 'data/ledger/ledgerApiCalls.js'
import useCustomKeycloak from 'keyclock/useKeyclock.js'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import {logo, xpIcon, arrowIcon, walletIcon, virtuaDefaultLogo} from '../../assets/images/images.js'

function Header() {
  const dropdownRef = useRef(null);
  const { doLogin, userInfo, doLogout } = useCustomKeycloak()
  const [isLoging, setIsLoging] = useState(false)
  const [isShowDropDown, setIsShowDropDown] = useState(false)
  const { updateAppState, appState } = useAppContext()

  const { balanceData, user_rank, badges } = appState
  const handleLoginClick = () => {
    setIsLoging(true)
    doLogin()
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropDown(false);
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (userInfo !== null) {
      setIsLoging(false)
      updateUserData(updateAppState, userInfo)
    }
  }, [userInfo])

  const currentLevelString = useMemo(() => {
    return balanceData?.level_progress?.current_level !== undefined &&
      balanceData?.level_progress?.current_level === 0
      ? '0'
      : balanceData?.level_progress?.current_level?.toString().padStart(2, '0') ?? '0'
  }, [balanceData?.level_progress?.current_level])
  return (
    <header id="header">
      <div>
        <a className="logo" href="/">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <ul className="navbar-nav-header list-none">
        <li className={`update-nav ${userInfo == null && 'd-none'}`}>
          <div className="season-level">
            <div className="season-list">
              <h5 className="text-capitalize">season</h5>
              <img src={xpIcon} alt="xp" />
              <span>{balanceData?.seasonal_xp ?? 0}</span>
            </div>
            <div className="level-list">
              <p className="text-capitalize">
                Level {currentLevelString}
                <span>
                  /{balanceData?.level_progress?.next_level?.toString().padStart(2, '0') ?? '0'}
                </span>
              </p>
            </div>
          </div>
          <div className="progress-bar">
            <span
              className={`path`}
              style={{ width: `${balanceData?.levels?.percentage ?? 0}%` }}></span>
          </div>
        </li>
        <div className={`authLinks ${userInfo !== null && 'd-none'}`} onClick={handleLoginClick}>
          <button
            className="login-btn d-flex align-items-center justify-content-center"
            onClick={handleLoginClick}>
            {isLoging === true ? (
              <Spinner size="sm" animation="border" />
            ) : (
              <span className="d-flex align-items-center justify-content-center">
                <img className="icon-img" src={walletIcon} alt="wallet-icon" /> Login
              </span>
            )}
          </button>
        </div>
        <li ref={dropdownRef} className={`${userInfo === null && 'd-none'} update-nav user-dropdown-nav`}>
          <div
            className={`${userInfo === null && 'd-none'}`}
            onClick={() => setIsShowDropDown(!isShowDropDown)}>
            <div className="user-profile">
              <div className="user-profile-img">
                <img src={userInfo?.picture ?? virtuaDefaultLogo} alt="user-image" />
              </div>
              <div className="user-profile-text">
                <div className="user-profile-text-left">
                  <p>{userInfo?.username ?? userInfo?.firstName}</p>
                  <p>
                    <span>Global</span>
                    <img src={xpIcon} alt="xp" />
                    <span className="mt-2">{Math.floor(Number(balanceData?.xp ?? 0))}</span>
                  </p>
                </div>
                <div className="user-profile-text-right">
                  <div className="arrow">
                    <img src={arrowIcon} alt="arrow" />
                  </div>
                </div>
              </div>
            </div>

            <div className={`user-profile-dropdown ${isShowDropDown ? 'd-block' : 'd-none'}`}>
              <div className="user-info">
                <div className="user-img">
                  <img
                    src={userInfo?.picture || virtuaUser}
                    alt="user-image"
                  />
                </div>
                <div className="user-name">
                  <h4>{userInfo?.username ?? userInfo?.firstName ?? ''}</h4>
                  <h5>{userInfo?.email ?? ''}</h5>
                  <p>
                    Global Rank: <span>{user_rank ?? ''}</span>
                  </p>
                  <div className="global-mob" style={{"display":"inlineFlex",}}>
                    <p>Global
                      <img src={xpIcon} alt="xp" />
                      <span className="">{Math.floor(Number(balanceData?.xp ?? 0))}</span></p>
                  </div>
                  <ul className="list-none">
                    {Array.isArray(badges) &&
                      badges?.map((item) => (
                        <li key={item._id}>
                          <img src={item?.icon ?? item.small_image ?? item.image} alt="badge" />
                        </li>
                      ))}
                  </ul>

                  <div className="w-100 d-flex justify-content-center">
                    <button
                      className="manage-account-btn"
                      onClick={() => {
                        window.open(process.env.REACT_APP_SETTINGS_URL, '_blank')
                      }}>
                      Manage your Account
                    </button>
                  </div>
                </div>
              </div>
              <div className="user-logout" onClick={doLogout}>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.65812 15.5906C7.65812 15.163 7.98874 14.8164 8.39658 14.8164H14.3043C14.4402 14.8164 14.5504 14.7008 14.5504 14.5583L14.5504 2.1712C14.5504 2.02867 14.4402 1.91313 14.3043 1.91313L8.39658 1.91313C7.98874 1.91313 7.65812 1.56652 7.65812 1.13894C7.65812 0.711366 7.98874 0.364746 8.39658 0.364746H14.3043C15.2559 0.364746 16.0273 1.17352 16.0273 2.1712V14.5583C16.0273 15.556 15.2559 16.3647 14.3043 16.3647H8.39658C7.98874 16.3647 7.65812 16.0181 7.65812 15.5906Z"
                    fill="#475467"></path>
                  <path
                    d="M11.9526 9.51594C11.9526 10.086 11.5118 10.5482 10.968 10.5482H6.18672C6.16415 10.9151 6.13588 11.2817 6.10194 11.6479L6.07273 11.9629C6.02524 12.4751 5.50639 12.7819 5.06369 12.5595C3.26389 11.6552 1.63457 10.4178 0.254083 8.90668L0.224586 8.8744C-0.0384038 8.58653 -0.0384037 8.13255 0.224586 7.84468L0.254084 7.81239C1.63457 6.3013 3.26389 5.06386 5.06369 4.15958C5.50639 3.93715 6.02524 4.24393 6.07273 4.75618L6.10194 5.07122C6.13588 5.4374 6.16415 5.804 6.18673 6.17089L10.968 6.17089C11.5118 6.17089 11.9526 6.63305 11.9526 7.20315V9.51594Z"
                    fill="#475467"></path>
                </svg>
                <p>Sign out</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </header>
  )
}
export default Header

import React from 'react'
import AOS from 'aos'
import ReactDOM from 'react-dom/client'

import './index.scss'
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import App from 'App'

AOS.init()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

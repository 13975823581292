/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, Form, Image, InputGroup, Spinner } from 'react-bootstrap'
import { apiBaseuUrl, emailRegex } from '../../../components/footer/constant'

import { SOCIAL_ICONS_GAMES } from 'utils/constants'

const JoinCommunityComponent = () => {
  const [emailError, setEmailError] = useState('')
  const [emailSuccessMsg, setEmailSuccessMsg] = useState('')
  const [emailVal, setEmailVal] = useState('')
  const [loading, setLoading] = useState(false)

  const [modalShow, setModalShow] = useState(false)
  const removeMsg = () => {
    emailError && setEmailError('')
    emailSuccessMsg && setEmailSuccessMsg('')
  }

  const handleClick = () => {
    if (!emailVal) {
      setEmailError('Please enter email.')
    } else if (!emailVal.match(emailRegex)) {
      setEmailError('Please enter valid email.')
    } else {
      setEmailError('')
      setLoading(true)
      axios
        .post(apiBaseuUrl, { email: emailVal.toLocaleLowerCase(), project_identifier: 'jetpack' })
        .then((res) => {
          if ([200, 201].includes(res.status)) {
            setEmailVal('')
            setLoading(false)
            setEmailSuccessMsg(res.data.message)
            setModalShow(true)
          }
        })
        .catch((res) => {
          setLoading(false)
          setEmailError(res.response.data.message)
        })
    }
  }

  useEffect(() => {
    let interval = setInterval(removeMsg, 3000)
    return () => clearInterval(interval)
  }, [emailError, emailSuccessMsg])

  return (
    <>
      <strong>Join Our Community</strong>
      <div className="input-holder-form">
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          handleClick()
        }}>
        <InputGroup className="input-holder">
          <Form.Control
            className="text-white"
            placeholder="Email Address"
            value={emailVal}
            onChange={(e) => setEmailVal(e.target.value)}
          />
          <Button className="input-holder-btn" type="submit" style={{ pointerEvents: loading ? 'none' : 'auto', height:45, width:75 }} >
            {!loading ? (
              <Image src="http://assets-cdn.virtua.com/images/Virtua_home/arrow.svg" alt="arrow" />
            ) : (
              <Spinner animation="border" size="sm"  />
            )}
          </Button>
        </InputGroup>
      </Form>
      {emailError && <div className="feedback error text-danger mt-2">{emailError}</div>}
      {!emailError && emailSuccessMsg && <div className="feedback text-success mt-2">{emailSuccessMsg}</div>}
      </div>
      <ul className="list-none footer-social-icons">
        {SOCIAL_ICONS_GAMES.map((list, index) => (
          <li key={list.alt}>
            <a className="flex items-center flex-text-center" href={list.href} target="_blank">
              <img src={list.src} alt={list.alt} className="sc-bBABsx cgoodp" />
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}

export default JoinCommunityComponent

import RoutesComponent from 'routes'
import { BrowserRouter } from 'react-router-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from 'keyclock/keyclock'
import AppContextProvider from 'AppContextProvider'

function App() {
  return (
    <BrowserRouter>
      <ReactKeycloakProvider authClient={keycloak}>
        <AppContextProvider>
          <RoutesComponent />
        </AppContextProvider>
      </ReactKeycloakProvider>
    </BrowserRouter>
  )
}

export default App

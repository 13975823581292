export const SOCIAL_ICONS = [
    // {
    //     src: "https://assets-cdn.virtua.com/images/play/jetpack/facebook.svg",
    //     alt: "facebook",
    //     href: "https://www.facebook.com/VirtuaMetaverse/",
    // },
    {
        src: "https://assets-cdn.virtua.com/images/play/jetpack/twitter.svg",
        alt: "twitter",
        href: "https://twitter.com/virtua_official",
    },
    {
        src: "https://assets-cdn.virtua.com/images/play/jetpack/instagram.svg",
        alt: "instagram",
        href: "https://www.instagram.com/Virtuametaverse/",
    },
    // {
    //     src: rabbit,
    //     alt: "rabbit",
    //     href: "",
    // },
    {
        src: "https://assets-cdn.virtua.com/images/play/jetpack/youtube.svg",
        alt: "youtube",
        href: "https://www.youtube.com/VirtuaMetaverse",
    },
    {
        src: "https://assets-cdn.virtua.com/images/play/jetpack/medium.svg",
        alt: "medium",
        href: "https://medium.com/virtuametaverse",
    },
    // {
    //     src: telegram,
    //     alt: "telegram",
    //     href: "",
    // },
];

export const apiBaseuUrl = process.env.REACT_APP_SUBSCRIBE_API_URL
export const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

export const getTruncatedAddress = (str) => {
    if (str !== '') {
        return str?.slice(0, 5) + str?.slice(-8).replace(/.(?=...)/g, '*')
    }
    return ''
}




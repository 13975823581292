import React, { memo, useState } from 'react'
import virtuaLogo from '../../../assets/imgs/virtua.png'
function GameItem({ gameItem, handleClick, index }) {
  const handleImageError = (event) => {
    // event.target.src = window.location.origin + '/logo192.png';
    event.target.src = virtuaLogo
  }

  return (
    <a
      className="game-item relative"
      rel="noopener noreferrer"
      data-testid={'game_row_item_' + index}
      onClick={() => {
        handleClick(gameItem)
      }}
      key={'row_item_' + (gameItem?.name ?? index)}>
      <img src={gameItem?.attributes?.icon ?? ''} onError={handleImageError} />
        <button className="custom-btn game-play-btn purple fw-600 lg rounded play-now" onClick={handleClick}>
            Play Now
            <svg
                width="22"
                height="22"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14 0C6.27291 0 0 6.27291 0 14C0 21.7271 6.27291 28 14 28C21.7271 28 28 21.7271 28 14C28 6.27291 21.7271 0 14 0ZM14 25.6334C7.58452 25.6334 2.3666 20.4155 2.3666 14C2.3666 7.58452 7.58452 2.3666 14 2.3666C20.4155 2.3666 25.6334 7.58452 25.6334 14C25.6334 20.4155 20.4155 25.6334 14 25.6334Z"
                    fill="white"
                />
                <path
                    d="M11.5764 18.8187C11.7475 18.9043 11.9186 18.9328 12.0897 18.9328C12.2893 18.9328 12.5174 18.8758 12.6885 18.7332L18.4481 14.7984C18.7333 14.5988 18.9043 14.2566 18.9043 13.9145C18.9043 13.5723 18.7333 13.2301 18.4196 13.0305L12.6599 9.20977C12.3178 8.98166 11.9186 8.98166 11.5479 9.15274C11.2058 9.35233 10.9777 9.69449 10.9777 10.0937V17.8493C11.0062 18.277 11.2058 18.6476 11.5764 18.8187Z"
                    fill="white"
                />
            </svg>
        </button>
    </a>
  )
}

export default memo(GameItem)

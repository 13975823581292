import { Auth, getAuth } from 'firebase/auth'
import { Database } from 'firebase/database'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Firestore, getFirestore } from 'firebase/firestore'
import { decryptMessage } from 'utils/utils'

export interface IAppConfigs {
  f_api_key: string
  f_app_id: string
  f_auth_domain: string
  f_measure_id: string
  f_message_sender_id: string
  f_project_id: string
  f_storage_bucket: string
  search_api_key: string
}
/* eslint-disable @typescript-eslint/no-extraneous-class */

class Firebase {
  private static firebaseInstance: Firestore | null = null
  private static firebaseApp: FirebaseApp | null = null
  private static readonly firebaseDBInstance: Database | null = null
  private constructor() {}

  private static readonly setupFirebase = (): void => {
    if (Firebase.firebaseInstance == null) {
      const firebaseConfig = JSON.parse(decryptMessage(process.env.REACT_APP_FIRESTORE))

      const app = initializeApp(firebaseConfig)
      Firebase.firebaseApp = app
      Firebase.firebaseInstance = getFirestore(app)
    }
  }

  public static isFirestoreConnected = (): boolean => {
    return Firebase.firebaseInstance !== null
  }

  public static getInstance = (): Firestore => {
    if (Firebase.firebaseInstance == null) {
      Firebase.setupFirebase()
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return Firebase.firebaseInstance!
  }

  public static getFirebaseAuth = (): Auth => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getAuth(Firebase.firebaseApp!)
  }
}

export default Firebase

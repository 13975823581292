/* eslint-disable react-hooks/exhaustive-deps */
import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useState } from 'react'

const useCustomKeycloak = () => {
    const { keycloak, initialized } = useKeycloak()

    const [userInfo, setUserInfo] = useState(null)
    const doLogin = async (rediractUri = undefined) => {
        if(rediractUri){
            await keycloak.login({redirectUri: rediractUri})
        }
        else {
            await keycloak.login()
        }
    }
    const doSignUp = async () => {
        await keycloak.register()
    }
    const doLogout = async () => {
        await keycloak.logout()
        window.location.reload()
    }

    const getUserInfo = async () => {
        if (keycloak.authenticated) {
            const res = await keycloak.loadUserInfo()
            setUserInfo({ ...res, picture: keycloak.tokenParsed?.picture|| null })
        }
    }

    useEffect(() => {
        getUserInfo()
    }, [])

    return (
        {
            keycloak,
            doLogin,
            doLogout,
            doSignUp,
            isKeycloakInit: initialized,
            isLoggedIn: keycloak.authenticated,
            userInfo,

        }
    )
}

export default useCustomKeycloak

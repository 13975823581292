import { SEC } from 'configs/config'

const CryptoJS = require('crypto-js')


// Function to encrypt a message
export function encryptMessage(message, key) {
  const encrypted = CryptoJS.AES.encrypt(message, key ?? SEC)
  return encrypted.toString()
}

// Function to decrypt a message
export function decryptMessage(encryptedMessage, key) {
  const decrypted = CryptoJS.AES.decrypt(encryptedMessage, key ?? SEC)
  return decrypted.toString(CryptoJS.enc.Utf8)
}

export function setDeviceIDFromGames(uuid) {
  if (uuid == null) localStorage.removeItem('uuid_game')
  else localStorage.setItem('uuid_game', uuid)
}
export function getDeviceIDFromGames() {
  return localStorage.getItem('uuid_game')
}

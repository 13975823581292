import { collection, doc, getDocs, getDoc, where, query } from 'firebase/firestore'
import Firebase from './Firebase.ts'
export const getBanners = async () => {
  try {
    const bannersCollection = collection(
      Firebase.getInstance(),
      '/environment_variables/play_virtua/banners'
    )

    const bannersDocs = await getDocs(bannersCollection)
    const results = []
    for (let banner of bannersDocs?.docs) {
      let bannerData = banner.data()
      results.push({
        ...bannerData,
        docID: banner.id
      })
    }
    return results
  } catch (error) {
    console.log('getBanners Error:', error)
    return null
  }
}

export const getGamerLoungeClaimStatus = async (userID) => {
  try {
    const profileGamerLounge = collection(
      Firebase.getInstance(),
      `/profile/${userID}/gamers_lounge`
    )
    const defaultLounge = await getDoc(doc(profileGamerLounge, 'default_lounge'))
    if (defaultLounge?.data()?.id !== undefined) {
      return { is_gamers_lounge_claimed: true }
    }
    return { is_gamers_lounge_claimed: false }
  } catch (error) {
    console.log('getBanners Error:', error)
    return { is_gamers_lounge_claimed: false }
  }
}
export const getGamerLoungeDocData = async () => {
  try {
    const bannersCol = collection(
      Firebase.getInstance(),
      `/environment_variables/play_virtua/banners`
    )
    const gamers_lounge = await getDoc(doc(bannersCol, 'gamers_lounge'))
    
    return gamers_lounge.data()
  } catch (error) {
    return null
  }
}

export const getClaimableAssetsDocID = async (uuid) => {
  try {
    const profileGamerLounge = collection(
      Firebase.getInstance(),
      `/gaming_api/claimable_asset_id/claimable_assets`
    )
    const querySnapshot = await getDocs(query(profileGamerLounge, where('device_id', '==', uuid)))
    
    return querySnapshot?.size >0 ? querySnapshot.docs[0].id: null
  } catch (error) {
    console.log('error:docID:', error)
    return null
  }
}

import { getGamerLoungeClaimStatus } from 'firestore/firestoreData'
import ledgerAxios from './ledgerAxios'

export const doBalanceApiCall = async (updateAppState) => {
  try {
    let responce = await ledgerAxios.get('balance')
    updateAppState({ balanceData: responce?.data?.payload })
  } catch (error) {
    console.log('2updateAppState:error:', error)
  }
}

export const getGameConversionRate = async (gameID) => {
  let data = {
    action: 'GAME',
    game_id: gameID
  }
  try {
    let responce = await ledgerAxios.post('/conversion-info', data)
    return responce?.data?.payload
  } catch (error) {
    console.log('getGameConversionRate:error:', error)
    return null
  }
}
export const updateUserData = async (updateAppState, userInfo) => {
  getGamerLoungeClaimStatus(userInfo?.sub)
    .then((gamerLoungeStatus) => {
      updateAppState({ ...gamerLoungeStatus })
    })
    .catch(() => {})

  ledgerAxios
    .get('balance')
    .then((balanceResponce) => {
      updateAppState({
        balanceData: balanceResponce?.data?.payload
      })
    })
    .catch(() => {})
  ledgerAxios
    .get('profile')
    .then((profileResponce) => {
      updateAppState({
        badges: profileResponce?.data?.payload?.badges ?? [],
        user_rank: profileResponce?.data?.payload?.user_rank ?? 0
      })
    })
    .catch(() => {})
}

import React, { useState, useEffect } from 'react'

function NoInternetMessage() {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine)
    }

    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])

  return (
    <div>
      {isOnline ? (
        <></>
      ) : (
        <div className="inner-content internetError">
          <h3 className="modal-title mb-4">Network Issue!</h3>
          <p>
            Your internet connection is weak, please find a stable network to experience these
            games.
          </p>
        </div>
      )}
    </div>
  )
}

export default NoInternetMessage

import { getClaimableAssetsDocID } from 'firestore/firestoreData'
import { getDeviceIDFromGames, setDeviceIDFromGames } from 'utils/utils'
import gamingAxios from './gamingAxios'

export const getGameListing = async () => {
  try {
    let responce = await gamingAxios.get('games?page=1&filter=vgn')
    return responce?.data?.payload?.rows ?? null
  } catch (error) {
    console.log('getGameListing:error:', error)
    return null
  }
}

export const syncGameScoreEvents = async () => {
  const uuid = getDeviceIDFromGames()
  if (uuid) {
    try {
      let docID = await getClaimableAssetsDocID(uuid)

      setDeviceIDFromGames(null)
      let responce = await gamingAxios.post(
        'vgn-assets-claim',
        {
          doc_id: docID
        },
        {
          headers: {
            device_id: uuid
          }
        }
      )
      return responce?.data?.payload?.rows ?? null
    } catch (error) {
      console.log('syncGameScoreEvents:error:', error)
      return null
    }
  }
}

import { useAppContext } from 'AppContextProvider'
import { getGameConversionRate } from 'data/ledger/ledgerApiCalls'
import useCustomKeycloak from 'keyclock/useKeyclock'
import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { DASHBOARD_CLAIM_LINK } from 'utils/constants'
import { boxIcon } from '../../assets/images/images'
import { sendGtagConverionEvent } from 'utils/googleTagConversion'

function ClaimXPModal({ showClaimXPModal, hideClaimModal, game, score }) {
  const { doLogin, userInfo } = useCustomKeycloak()
  const [xpValue, setXPValue] = useState(0)
  const { appState } = useAppContext()
  const handleClaim = () => {
    if (userInfo == null) {
      // doLogin(`${window.location.origin}/handle_claim`)
      doLogin(`${window.location.origin}?handle_claim=true`)
      sendGtagConverionEvent('claim_xp_button_clicked', 'AW-11303637897/ji5jCOi8t9sYEImn_40q', '')
      return
    }

    if (appState?.is_gamers_lounge_claimed === false) {
      window.open(DASHBOARD_CLAIM_LINK, '_blank')
    }
  }

  useEffect(() => {
    if (showClaimXPModal && game?.uuid) {
      getGameConversionRate(game?.uuid)
        .then((res) => {
          if (res?.rate) {
            setXPValue((res?.rate ?? 0) * score)
          }
        })
        .catch((ex) => {})
      sendGtagConverionEvent('claim_xp_not_logged_in', 'AW-11303637897/SIWlCJj5tdsYEImn_40q', '')
    }
  }, [game?.uuid, showClaimXPModal])

  const getXP = useCallback(() => {
    if (xpValue == 0) return ''
    return Number.isInteger(xpValue) ? xpValue : xpValue?.toFixed(2)
  }, [xpValue])

  return (
    <>
      {showClaimXPModal && (
        <Modal
          size="w-650"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
          show
          className="claimModal">
          <div className="game-modal">
            <img
              src={process.env.REACT_APP_S3_IMGS_URL + 'close.svg'}
              alt="close"
              className="modal-close-icon"
              onClick={hideClaimModal}
            />
            <div className="modal-holder">
              <div className="modal-content-game relative">
                <div className="inner-content">
                  <h3 className="modal-title">You've earned<br/> <span className="truncate-xp">{getXP()}</span> XP!</h3>
                  <img src={boxIcon} alt="box" />
                  <p>
                    Claim your XP to win exclusive rewards including vCoin packs, resources and also get access to a free Gamers Lounge, where you can experience more VGN games!
                  </p>
                  <button className="claim-btn" onClick={handleClaim}>
                    Claim
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ClaimXPModal

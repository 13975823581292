import React, { useCallback, useEffect, useState } from 'react'
import { metaverseIcon, metaverseMob } from './assets/images/images'
import './assets/css/style.css'
import GameIframeComponent from './components/iframes/GameIframeComponent'
import Header from './components/header/Header'
import GameItem from './components/items/GameItem'
import { getGameListing, syncGameScoreEvents } from 'data/gaming/gamingApiCalls'
import GameDetailComponent from './components/gamedetail/GameDetailComponent'
import BannersComponent from './components/banners/BannersComponent'
import { Spinner } from 'react-bootstrap'
import JoinCommunityComponent from './components/footer/JoinCommunityComponent'
import { useAppContext } from 'AppContextProvider'
import useCustomKeycloak from 'keyclock/useKeyclock'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import NoInternetMessage from './components/modals/NoInternetMessage'

function HTMLGamesComponent() {
  const [seletedGame, setSelectedGame] = useState(null)
  const [gamesList, setGamesList] = useState([])
  const [isLoadingGames, setIsLoadingGames] = useState(false)
  const { appState } = useAppContext()
  let { gameName } = useParams()

  const navigate = useNavigate()
  const { isLoggedIn } = useCustomKeycloak()
  const handleGameItemClick = useCallback((gameItem) => {
    setSelectedGame(gameItem)
  }, [])

  useEffect(() => {
    let gamePathName = seletedGame?.name?.replace(' ', '-')?.toLowerCase()
    if (gamePathName) {
      navigate(`/${gamePathName}`)
    }
  }, [seletedGame?.name])

  useEffect(() => {
    setIsLoadingGames(true)
    getGameListing()
      .then((gamesList) => {
        if (gamesList !== null) {
          setGamesList(gamesList)
          if (gameName) {
            for (let game of gamesList) {
              let gamePathName = game?.name?.replace(' ', '-')?.toLowerCase()
              if (gameName == gamePathName) {
                setSelectedGame(game)
                break
              }
            }
          }
        }
        setIsLoadingGames(false)
      })
      .catch((ex) => {
        setIsLoadingGames(false)
        console.log('gamesList::ex:', ex)
      })
  }, [])
  useEffect(() => {
    if (isLoggedIn === true) {
      syncGameScoreEvents()
    }
  }, [isLoggedIn])

  useEffect(() => {
    document.body.classList.add('scrollbar-vgn')

    return () => {
      document.body.classList.remove('scrollbar-vgn')
    }
  }, [])

  return (
    <div className="App">
      <div id="wrapper">
        <Header />
        <div className="container">
          <div className="gaming-main-area flex gap-20">
            <div className="gaming-left-side flex-1">
              <div className="flex gap-20">
                <div className="gaming-images-holder flex column-direction gap-20">
                  {isLoadingGames && (
                    <div className="loading_game-play-container">
                      <Spinner animation="border" />
                    </div>
                  )}
                  {gamesList?.map((gameItem, index) => (
                    <GameItem
                      index={index}
                      gameItem={gameItem}
                      handleClick={handleGameItemClick}
                      key={gameItem?.uuid ?? 'Game_Item_' + index}
                    />
                  ))}
                </div>
                <div className="relative w-100">
                  <div>
                    <NoInternetMessage />
                    <ErrorBoundary fallback={<div>Something went wrong. Please try again.</div>}>
                      <GameIframeComponent gameItem={seletedGame} />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
              <GameDetailComponent seletedGame={seletedGame} />
              <div className="gaming-metaverse relative">
                <picture>
                  <source media="(min-width: 600px)" srcSet={metaverseIcon} />
                  <source media="(min-width: 320px)" srcSet={metaverseMob} />
                  <img src={metaverseIcon} />
                </picture>
                {/*<img src={metaverseIcon} alt="metaverse" />*/}
                <div className="text">
                  <a href={process.env.REACT_APP_DASHBOARD_URL} target="_blank" rel="noreferrer">
                    <button className="custom-btn purple md fw-600">Explore More</button>
                  </a>
                </div>
              </div>
            </div>
            <BannersComponent />
          </div>
        </div>
        <footer id="footer" className="games-footer">
          <div className="footer-content">
            <div className="footer">
              <div className="footer-left">
                <div className="footer-left-inner">
                  <div className="footer-col">
                    <strong>Explore</strong>
                    <ul className="list-none">
                      <li>
                        <a href={process.env.REACT_APP_S3_PRIME_URL}>Virtua Prime</a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_S3_ISLAND_URL}>Virtua Island</a>
                      </li>
                      <li>
                        <a href={process.env.REACT_APP_S3_BOTS_URL}>Land Bots</a>
                      </li>
                      {/* <li>
                        <a href={process.env.REACT_APP_S3_LAB_URL}>Virtua Labs</a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="footer-col">
                    <strong>About</strong>
                    <ul className="list-none">
                      <li>
                        <a href="https://virtua.gitbook.io/virtua-wiki/" target="_blank">
                          Wiki
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://virtua.gitbook.io/virtua-wiki/welcome-to-the-metaverse/the-virtua-team"
                          target="_blank">
                          Our Team
                        </a>
                      </li>
                      <li>
                        <a href="https://blog.virtua.com/" target="_blank">
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-col">
                    <strong>Links</strong>
                    <ul className="list-none">
                      <li>
                        <a href={process.env.REACT_APP_S3_MARKETPLACE_URL}>Marketplace</a>
                      </li>
                      {/* <li>
                        <a
                          href="https://virtua.gitbook.io/virtua-wiki/welcome-to-the-metaverse/tvk/prestige"
                          target="_blank">
                          Prestige Program
                        </a>
                      </li> */}
                      {/* <li>
                        <a href={process.env.REACT_APP_S3_TVK_URL}>Virtua Kolect</a>
                      </li> */}
                      <li>
                        <a href={process.env.REACT_APP_S3_CONTACT_URL} target="_blank">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex items-center web-footer-text">
                  <img
                    src="http://assets-cdn.virtua.com/images/Virtua_home/virtua-footer.svg"
                    alt="virtua"
                  />
                  <span className="title">
                    Virtua is a registered trademark of Virtua Limited. All logos are registered trademarks of their respective owners. All content of this document, unless otherwise credited, are copyright @ 2024 Virtua Limited.
                  </span>
                </div>
              </div>
              <div className="footer-right">
                <div className="footer-right-inner">
                  <div className="right-inner-content">
                    <JoinCommunityComponent />
                  </div>
                  <div className="footer-buttons">
                    <a
                      href="https://apps.apple.com/pk/app/virtua-metaverse/id1597744060"
                      target="_blank"
                      className="btn-footer">
                      <span className="icon">
                        <img
                          src="http://assets-cdn.virtua.com/images/Virtua_home/ios.svg"
                          alt="ios"
                        />
                      </span>
                      App Store
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.terravirtua.prod"
                      target="_blank"
                      className="btn-footer">
                      <span className="icon">
                        <img
                          src="http://assets-cdn.virtua.com/images/Virtua_home/android.svg"
                          alt="android"
                        />
                      </span>
                      Google Play
                    </a>
                  </div>
                  <div className="mobile-footer-text"><img src="http://assets-cdn.virtua.com/images/Virtua_home/virtua-footer.svg" alt="virtua" />
                    <span className="title">Virtua is a registered trademark of Virtua Limited. All logos are registered trademarks of their respective owners. All content of this document, unless otherwise credited, are copyright @ 2023 Virtua Limited.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer-bootom-list">
          <div className="container">
            <div className="bottom-wrap">
              <ul className="list-none flex items-center user-guidelines">
                <li>
                  <a rel="noreferrer" href={process.env.REACT_APP_S3_GUIDELINE_URL} target="_blank">
                    User Guidelines
                  </a>
                </li>
                <li>
                  <a rel="noreferrer" href={process.env.REACT_APP_S3_POLICY_URL} target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a rel="noreferrer" href={process.env.REACT_APP_S3_TERMS_URL} target="_blank">
                    Terms of Service
                  </a>
                </li>
              </ul>
              <div className='flex items-center powered-text'>
                Powered by
                <a href="https://vanarchain.com/" target="_blank" rel="noreferrer">
                  <img src="https://assets-cdn.virtua.com/images/Virtua_home/vanar.svg" alt="powered-by-vanar" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HTMLGamesComponent

import axios from 'axios'
import keycloak from 'keyclock/keyclock'
import { LEDGER_API_BASE } from 'utils/constants'

const ledgerAxios = axios.create({
  baseURL: LEDGER_API_BASE,
  timeout: 60000
})
ledgerAxios.interceptors.request.use(function (config) {
    config.headers.Authorization =  keycloak?.token ??  '';
    return config;
});
export default ledgerAxios
